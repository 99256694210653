.nav {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  border-bottom: 1px solid var(--borderColor);
  margin-bottom: var(--scale-00);
  font-size: var(--scale-00);
}

.nav__list {
  list-style-type: none;
  padding-left: 0;
  margin-top: var(--scale-00);
  margin-bottom: var(--scale-00);
}

.nav__list:last-child {
  margin-left: var(--scale-00);
  padding-left: var(--scale-00);
  border-left: 1px solid var(--borderColor);
}
