.input {
  font-size: var(--scale-0);
  font-weight: normal;
  padding: 0 14px 0 14px;
  height: 40px;
  width: 100%;
  /* line-height: 1.25; */
  border: 1px solid var(--borderColor);
  /* border-color: rgb(0 0 0 / 0%); */
  /* border-bottom-color: rgb(30 31 33); */
  background-color: var(--backgroundNeutral);
  display: inline-block;
  float: left;
  text-align: left;
  border-radius: var(--radiusS);
  color: var(--textColor);
  font-family: inherit;
  /* background-color: #292a2d; */
}

.input:focus {
  background-color: #fff;
  outline: none;
  /* color: var(--backgroundNeutral); */
  /* border-color: var(--highlightColor); */
  /* border-color: #141417; */
  /* box-shadow: 0 0 3px 0px rgb(0 0 0 / 28%); */
}

.input__label {
  user-select: none;
}

.input__error {
  user-select: none;
  font-size: var(--scale-000);
  color: var(--dangerColor);
  margin-left: 15px;
  margin-top: -6px;
  background: var(--backgroundNeutral);
  position: absolute;
}

@media (prefers-color-scheme: dark) {
  :root:not(.theme-light) .input:focus {
    /* background-color: var(--textColor); */
    outline: none;
    /* color: var(--backgroundNeutral); */
    /* border-color: var(--highlightColor); */
    background-color: var(--borderColor);
    /* border-color: #141417; */
    /* box-shadow: 0 0 3px 0px rgb(0 0 0 / 28%); */
  }
}
