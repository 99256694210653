@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating {
  animation: rotating 2s linear infinite;
}

@keyframes text-shadow-lr {
  0% {
    text-shadow: -1px -1px 0 var(--backgroundNeutral), 0 0 2px rgb(105 121 171),
      2px -2px 0 var(--brand);
  }
  100% {
    text-shadow: 4px -1px 0 var(--backgroundNeutral), 2px 0 2px rgb(143 105 171),
      2px -2px 0 var(--brand);
  }
}
