.footer {
  display: flex;
  flex-flow: column nowrap;
  font-size: var(--scale-00);
  line-height: var(--line-sm);
}

.footer__paragraph {
  margin: 0;
}
