.login {
  width: auto;

  /* align with the left side but keep the larger tap target. 24px is the icon's native width */
  right: 0;
  top: 0;
  z-index: var(--layer-3);
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  background: var(--backgroundNeutral);
}

.login__dialog {
  max-width: 400px;
}

.login__button {
  width: auto;
  margin-left: auto;
  margin-top: var(--size-7);
}

.login__button:focus,
.login__button:hover {
  background: var(--borderColor);
}

.login__icon {
  width: 20px;
}

.dialog {
  padding: var(--size-7);
  border-radius: var(--radius);
  box-shadow: 0 1px 1px 0 #141416, 0 2px 3px 0 #111112, 0 3px 4px 0 #1d1d20, 0 4px 1px 0 #070708,
    0 5px 5px 0 #45484e, 0 4px 17px -7px #040404, 0 0 0 50vmax rgb(255 255 255 / 10%);
}

.dialog__title {
  margin-top: 0;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  pointer-events: none;
  overflow-y: auto;
}

.modalOverlay > * {
  pointer-events: all;
}

@media (max-width: 419px) {
  .login--signInVisible {
    width: 100%;
    padding-bottom: 20px;
  }
}
