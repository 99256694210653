.menu__icon {
  /* Do we need this and in login? */
  width: 20px;
}

.menu {
  --shadow: 0 1px 1px 0 #dbdddd, 0 2px 3px 0 #e5e5e5, 0 3px 4px 0 #cdcdcd, 0 4px 1px 0 #b7b7b7,
    0 5px 5px 0 #ffffff, 0 4px 17px -7px #8b8b8b;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 6px;
  padding: var(--size-1);
  box-shadow: var(--shadow);
  max-width: 300px;
  background: var(--backgroundNeutral);
}

.menu__arrow {
  fill: var(--backgroundNeutral);
}

.menu__separator {
  height: 1px;
  background-color: var(--borderColor);
  margin: 5px;
}

.menu__item {
  display: block;
  flex-flow: row nowrap;
  padding: 0 25px 0 25px;
  height: 25px;
  position: relative;
  line-height: 25px;
  border-radius: 3px;
  align-items: center;
  cursor: pointer;
  color: var(--linkColor);
  font-size: var(--scale-00);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;
}

.menu__item[data-disabled],
.menu__label {
  display: flex;
  flex-flow: row nowrap;
  padding: 0 25px 0 25px;
  height: 25px;
  position: relative;
  line-height: 25px;
  border-radius: 3px;
  align-items: center;
  cursor: default;
  user-select: none;
}

.menu__label {
  font-size: 0.8125rem;
}

.menu__item:hover,
.menu__item:focus {
  background-color: var(--borderColor);
  color: var(--interactiveTextHover);
}

.menu__item:hover .ai-Sun,
.menu__item:focus .ai-Sun {
  stroke: var(--interactiveTextHover);
}

.menu__item:hover .ai-SunFill,
.menu__item:focus .ai-SunFill {
  fill: var(--interactiveTextHover);
}

.menu__radioIndicator {
  position: absolute;
  left: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 25px;
  width: 14px;
  height: 14px;
  top: 6px;
}

@media (prefers-color-scheme: dark) {
  :root:not(.theme-light) .menu {
    --shadow: 0 1px 1px 0 #141416, 0 2px 3px 0 #111112, 0 3px 4px 0 #1d1d20, 0 4px 1px 0 #070708,
      0 5px 5px 0 #212224, 0 4px 17px -7px #040404;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shadowIn {
  from {
    box-shadow: 0 1px 0 0 rgb(255 255 255 / 0), 0 2px 0 0 rgb(255 255 255 / 0),
      0 3px 0 0 rgb(255 255 255 / 0), 0 4px 0 0 rgb(255 255 255 / 0), 0 5px 0 0 rgb(255 255 255 / 0),
      0 4px 0 -7px rgb(255 255 255 / 0);
  }
  to {
    box-shadow: var(--shadow);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.96);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.menu[data-state='open'] {
  animation: shadowIn 600ms ease-out, scaleIn 200ms ease-out, fadeIn 200ms ease-out;
}

.menu[data-state='closed'] {
  animation: fadeOut 300ms ease-in;
}
