:root {
  --backgroundNeutral: #e9eaea;
  --brand: #10a1cf;
  --cta: linear-gradient(to right, #ffb347, #ffcc33);
  --ctaSecondary: linear-gradient(31deg, rgb(105 121 171), rgb(0 164 173));
  --textColor: #333;
  --contrastColor: var(--textColor);
  --linkColor: #0066c0;
  --verticalGap: 30px;
  --horizontalGap: 30px;
  --dangerColor: #e28080;
  /*   --highlightGradient: linear-gradient(to right, #ffb347, #ffcc33); */
  --highlightGradient: linear-gradient(31deg, #3e4867, #006267);
  --highlightColor: #ffb347;
  --column: 708px;
  --borderColor: #fff;
  --borderContrastColor: #c9d5e1;

  --roundness: 8px;
  --radiusXL: calc(var(--roundness) * 4);
  --radiusL: calc(var(--roundness) * 2);
  --radius: var(--roundness);
  --radiusS: calc(var(--roundness) / 2);
  --radiusXS: calc(var(--roundness) / 4);
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--backgroundNeutral);
  margin: 0;
  color: var(--textColor);
  line-height: var(--line-xs);
  font-size: 0.9375rem;
}

a {
  color: var(--linkColor);
  /* color: #8282cf; */
  text-decoration: none;
}

p {
  line-height: var(--line-md);
  font-size: 0.9375rem;
}

a:hover,
a:focus {
  text-decoration: underline;
}

label {
  font-size: var(--scale-00);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

label {
  user-select: none;
}

datalist {
  display: flex !important;
  flex-flow: row nowrap;
}

::selection {
  background: var(--brand);
  color: #fff;
}

:focus {
  /* outline: var(--brand) solid 1px; */
  outline: none;
}

input:-webkit-autofill {
  background-color: var(--backgroundNeutral) !important;
  background-image: none !important;
}

input:autofill {
  background-color: var(--backgroundNeutral) !important;
  background-image: none !important;
}

:where(:root) {
  cursor: initial; /* Override "1" from opinionated rules in sanitize.css */
}

@media (prefers-color-scheme: dark) {
  :root:not(.theme-light) {
    --backgroundNeutral: hsl(220deg 5% 10%);
    --textColor: #9b9b9b;
    --buttonTextColor: #dfecf1;
    /*--interactiveText: #62a4b5;*/
    --interactiveTextHover: #9aa4cd;
    --contrastColor: var(--textColor);
    --linkColor: #7b7bb9;
    --brand: #2d92ac;
    --highlightColor: #e2a468;
    --highlightGradient: linear-gradient(31deg, rgb(105 121 171), rgb(0 164 173));
    --ctaSecondary: linear-gradient(31deg, #3e4867, #006267);
    --borderColor: #292a2d;
    --borderContrastColor: #101112;
    /* filter: grayscale(1); */
  }
}

@media (max-width: 360px) {
  :root {
    --horizontalGap: 15px;
    --verticalGap: 15px;
  }
}

@media (pointer: coarse) {
  .mouse-only {
    display: none;
  }
}
