.appLayout .footer {
  margin-top: 40vh;
}

.appLayout h1 > a {
  text-decoration: none;
}

.appLayout h1 > a.active {
  cursor: default;
  pointer-events: none;
}
