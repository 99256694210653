.app h1 {
  color: var(--brand);
  font-family: 'Tulpen One', sans-serif;
  font-size: calc(87px + (112 - 87) * ((100vw - 320px) / (1920 - 320)));
  margin: 0 0 calc(var(--verticalGap) - 10px) 0;
  cursor: default;
  line-height: var(--line-none);
  width: auto;
  display: inline-block;
  letter-spacing: 5px;
  user-select: none;
  background: linear-gradient(31deg, rgb(105 121 171), rgb(0 164 173));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* opacity: 0.4; */
  /* filter: grayscale(0.5); */
  text-shadow: 1px -1px 0 var(--backgroundNeutral), 2px -2px 0 var(--brand);
}

#root {
  margin: 6%;
}

.app h1.animate--text-shadow-lr {
  animation: text-shadow-lr 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite alternate both;
}

h1:hover {
  /* text-shadow: -3px 3px 0 #fff, -6px 6px 0 #f03ea4; */
}

h3 {
  font-size: var(--scale-000);
  color: #666;
  margin-top: 3.6%;
  margin-bottom: 3.6%;
}

.app {
  padding: 0 0 50px 0;
  margin: 4% auto 0 auto;
  position: relative;
  max-width: var(--width-md);
  z-index: var(--layer-2);
}

/* OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD */
/* OLD OLD OLD OLD OLD OLD OLD OLD OLD  BELOW  OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD */
/* OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD OLD */

#flexb {
  display: flex;
  width: 100%;
}

/* #weight {
  display: inline-block;
  background: #ffffff;
  padding: 9px 0;
  width: 49.22%;
  height: 19px;
  outline: 0;
  border-radius: var(--radiusXS);
  border: silver 1px solid;
  margin-top: 20px;
  transition: all 0.2s ease;
  font-size: var(--scale-0);
  text-align: center;
  color: #333;
} */

/*********************** BEGIN MALE *****************************/

#male {
  display: inline-block;
  background: #3eb7f0;
  color: #fff;
  padding: 10px 0;
  height: 39px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
  width: 50%;
  outline: 0;
  border-radius: var(--radiusXS);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 0;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease, width 0.2s ease, height 0.2s ease, margin 0.2s ease,
    border 0.2s ease, left 0.2s ease, color 0.2s ease, opacity 0.2s ease;
  font-size: var(--scale-0);
}

.genderthen {
  background: #fff !important;
  color: #aaa !important;
  border: silver 1px solid !important;
  cursor: default !important;
  height: 39px;
  text-shadow: none !important;
}

#male:hover {
  background: #3ba7da;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

#male:active {
  box-shadow: inset 1px 1px 1px rgb(65, 111, 153), 1px 1px 1px rgb(255, 255, 255);
  color: #eee;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
}

/************************ END MALE *****************************/

#flexwrap {
  display: flex;
  justify-content: space-between;
  color: #fff;
  outline: 0;
  border-radius: var(--radiusXS);
  border: 0;
  transition: all 0.2s ease;
  font-size: var(--scale-0);
  width: 100%;
  position: absolute;
}

#flexwres {
  display: flex;
  justify-content: space-between;
  color: #fff;
  outline: 0;
  border-radius: var(--radiusXS);
  border: 0;
  transition: all 0.2s ease;
  font-size: var(--scale-0);
  width: 100%;
  position: absolute;
  z-index: var(--layer-1);
}

/*********************** BEGIN FEMALE *****************************/

#female {
  display: inline-block;
  background: #3eb7f0;
  color: #fff;
  padding: 10px 0;
  height: 39px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
  width: 50%;
  outline: 0;
  border-radius: var(--radiusXS);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border: 0;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease, width 0.2s ease, height 0.2s ease, margin 0.2s ease,
    border 0.2s ease, left 0.2s ease, color 0.2s ease, opacity 0.2s ease;
  font-size: var(--scale-0);
}

#female:hover {
  background: #3ba7da;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

#female:active {
  box-shadow: inset 1px 1px 1px rgb(65, 111, 153), 1px 1px 1px rgb(255, 255, 255);
  color: #eee;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
}

/************************ END FEMALE *****************************/

.list-disabled li:hover:after {
  content: '' !important;
}

.list-disabled li:hover {
  color: #666 !important;
}

.list-disabled li {
  color: #666 !important;
  cursor: default !important;
}

.button-disabled,
.button-disabled:hover,
.button-disabled:active {
  background: #43c762 !important;
  color: #fff !important;
  box-shadow: none !important;
  text-shadow: none !important;
  cursor: default !important;
}

a {
  color: inherit;
  text-decoration: inherit;
}

#to-do-input {
  font-size: var(--scale-00);
  font-weight: normal;
  padding: 5px 0 5px 5px;
  width: 100%;
  border: 1px solid Silver;
  background-color: White;
  display: inline-block;
  float: left;
  text-align: left;
  border-radius: var(--radiusXS);
  color: #333;
}

#to-do-list {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

#to-do-list li {
  padding: 5px 0 5px 5px;
  border-bottom: 1px dashed silver;
}

#to-do-list li:hover {
  color: #db6060;
  cursor: pointer;
}

#to-do-list li:hover:after {
  content: '  Remove';
  color: silver;
  font-size: var(--scale-00);
}

.App {
  padding: 2% 0 0;
  margin: auto;
  position: relative;
}

#result-panel {
  opacity: 0;
  background: #ffffff;
  color: #fff;
  padding: 10px 30px;
  padding-right: 40px;
  outline: 0;
  border-radius: var(--radiusXS);
  border: 0;
  margin-top: 20px;
  transition: all 0.2s ease;
  font-size: var(--scale-0);
}

#calculate-all {
  background: #3eb7f0;
  color: #fff;
  padding: 10px 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 39px;
  outline: 0;
  border-radius: var(--radiusXS);
  border: 0;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease, width 0.2s ease, height 0.2s ease, margin 0.2s ease,
    border 0.2s ease, left 0.2s ease, color 0.2s ease, opacity 0.2s ease;
  font-size: var(--scale-0);
  display: inline-block;
  position: absolute;
}

#calculate-all:hover {
  background: #3ba7da;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

#calculate-all:active {
  box-shadow: inset 1px 1px 1px rgb(65, 111, 153), 1px 1px 1px rgb(255, 255, 255);
  color: #eee;
  text-shadow: 0 0 0 black;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
}

#showres {
  background: #3eb7f0;
  color: #fff;
  padding: 10px 0;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 39px;
  outline: 0;
  border-radius: var(--radiusXS);
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s ease, width 0.2s ease, height 0.2s ease, margin 0.2s ease,
    border 0.2s ease, left 0.2s ease, color 0.2s ease, opacity 0.2s ease;
  font-size: var(--scale-0);
  display: block;
  z-index: var(--layer-below);
  position: absolute;
  top: 80px;
  left: 0;
  text-align: center;
  -webkit-touch-callout: none;
  user-select: none;
  opacity: 0;
}

#showres:hover {
  background: #3ba7da;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

#showres:active {
  box-shadow: inset 1px 1px 1px rgb(65, 111, 153), 1px 1px 1px rgb(255, 255, 255);
  color: #eee;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
}

#weight-unit {
  color: #5ebeec;
  position: absolute;
  bottom: 10px;
  right: 58.2%;
  transition: all 0.1s ease;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#weight-unit:hover {
  color: var(--brand);
}

#in-button {
  display: inline-block;
  width: 75px;
}

@media (min-width: 747px) {
  .App {
    width: 718px;
  }
}
