.dialog {
  --shadow: 0 1px 1px 0 #dbdddd, 0 2px 3px 0 #e5e5e5, 0 3px 4px 0 #cdcdcd, 0 4px 1px 0 #b7b7b7,
    0 5px 5px 0 #ffffff, 0 4px 17px -7px #8b8b8b;
  background: var(--backgroundNeutral);
  padding: var(--horizontalGap);
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  will-change: box-shadow, transform, opacity;
  max-width: 600px;
}

.dialog p {
  line-height: var(--line-md);
}

.dialog__title {
  margin-top: 0;
  margin-bottom: var(--verticalGap);
}

.dialog__x {
  position: absolute;
  top: var(--size-2);
  right: var(--size-2);
}

.dialog__overlay {
  position: fixed;
  inset: 0;
  display: grid;
  place-items: center;
  pointer-events: none;
  overflow-y: auto;
  z-index: 20;
  background: rgb(255 255 255 / 10%);
  will-change: transform, opacity;
  background-image: linear-gradient(
    330deg,
    hsl(272deg 57% 25% / 10%) 0%,
    hsl(196deg 78% 24% / 10%) 100%
  );
  backdrop-filter: blur(8px);
  padding: 6%;
}

@media (prefers-color-scheme: dark) {
  :root:not(.theme-light) .dialog {
    --shadow: 0 1px 1px 0 #141416, 0 2px 3px 0 #111112, 0 3px 4px 0 #1d1d20, 0 4px 1px 0 #070708,
      0 5px 5px 0 #45484e, 0 4px 17px -7px #040404;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes shadowIn {
  from {
    box-shadow: 0 1px 0 0 rgb(255 255 255 / 0), 0 2px 0 0 rgb(255 255 255 / 0),
      0 3px 0 0 rgb(255 255 255 / 0), 0 4px 0 0 rgb(255 255 255 / 0), 0 5px 0 0 rgb(255 255 255 / 0),
      0 4px 0 -7px rgb(255 255 255 / 0);
  }
  to {
    box-shadow: var(--shadow);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.96);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* TODO: Separate overlay and dialog animations + animate shadow */
.dialog__overlay[data-state='open'],
.dialog[data-state='open'] {
  animation: fadeIn 200ms ease-out;
}

.dialog[data-state='open'] {
  animation: shadowIn 600ms ease-out, scaleIn 200ms ease-out;
}

.dialog[data-state='closed'] {
  animation: fadeOut 300ms ease-in;
}
