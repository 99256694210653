.auth {
  width: auto;

  /* align with the left side but keep the larger tap target. 24px is the icon's native width */
  right: 0;
  top: 0;
  z-index: var(--layer-3);
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-end;
  background: var(--backgroundNeutral);
}

.auth__button.button {
  width: 40px;
  /* margin-right: calc(-1 * (40px / 2 - 24px / 2)); */
  margin-top: calc(-1 * (40px / 2 - 24px / 2));
  height: 40px;
}

.auth__icon {
  width: 20px;
}

@media (max-width: 419px) {
  .auth--signInVisible {
    /* width: 100%; */
    /* padding-bottom: 20px; */
  }
}
