.button {
  color: var(--buttonTextColor);
  background: var(--contrastColor);
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 11px 20px;
  outline: 0;
  border-radius: var(--radiusS);
  border: 0;
  cursor: pointer;
  font-size: var(--scale-00);
  user-select: none;
  transition: box-shadow 100ms cubic-bezier(0, 0.08, 0.25, 1),
    transform 100ms cubic-bezier(0, 0.08, 0.25, 1), filter 1s cubic-bezier(0, 0.08, 0.25, 1);
  /* box-shadow: inset 0px -1px 0px 0 rgb(22 26 30), inset 0px 1px 0 0 rgb(64 66 70); */
}

.buttonPrimary {
  background: var(--ctaSecondary);
  color: #dfecf1;
  /* box-shadow: 0px 2px 1px rgb(33 61 76); */
  /* background: #ff00ff; */
  /* color: #FFF; */
  /* box-shadow: inset 0 0 2px 1px rgb(165 80 255), inset 0 0 2px 2px rgb(79 55 185), inset 0 0 3px 3px rgb(75 134 172 / 32%), 0 0 15px -5px #713d8e, 0px 2px 1px rgb(125 47 210); */
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 20%), inset 0 1px 0 0 rgb(255 255 255 / 20%);
}

.buttonTransparent {
  /* background: var(--backgroundNeutral); */
  background: transparent;
  padding: 11px 20px;
  /* background: linear-gradient(31deg, hsl(225deg 13% 15%), hsl(183deg 13% 11%)); */
  /* box-shadow: 0px 1px 0px 0 rgb(0 0 0 / 20%), inset 0px 1px 0 0 rgb(72 72 72 / 20%); */
}

.buttonTransparent:focus,
.buttonTransparent:hover {
  background: #fff;
}

.borderOnly {
  border: 1px solid var(--borderColor);
  background: none;
  box-shadow: 0 0 0 1px var(--borderContrastColor);
  color: var(--textColor);
}

.borderOnly:focus,
.borderOnly:hover {
  background: var(--borderColor);
}

.icon {
  width: 40px;
  min-width: 40px;
  height: 40px;
}

.icon img {
  width: 20px;
}

.button:focus {
  outline: none;
}

.button--showChild,
.button--showChild:hover,
.button--showChild:active,
.button--showChild:focus {
  background: none;
  display: contents;
  color: inherit;
}

.buttonPrimary:focus,
.buttonPrimary:hover {
  background: var(--highlightGradient);
  color: #cddfe6;
  /* color: var(--backgroundNeutral); */
  /* filter: hue-rotate(45deg); */
  /* box-shadow: 0px 1px 0px 0 rgb(0 0 0 / 20%), inset 0px 0px 1px 1px rgb(255 255 255 / 20%); */
}

.buttonPrimary:active {
  box-shadow: 0 0 0 0 rgb(0 0 0 / 20%), inset 0 0 0 0 rgb(255 255 255 / 20%);
  /* transform: translateY(1px); */
}

.css-selector {
  background: linear-gradient(239deg, #00ffbd, #fb00ff);
  background-size: 400% 400%;

  animation: AnimationName 1s ease infinite;
}

@keyframes AnimationName {
  0% {
    background-position: 46% 0;
  }
  50% {
    background-position: 55% 100%;
  }
  100% {
    background-position: 46% 0;
  }
}

.button--main.buttonLoading,
.button--main.buttonLoading:hover,
.button--main.buttonLoading:focus,
.button--main.buttonLoading:active {
  background: #43c762;
  box-shadow: none;
  cursor: default;
}

.button--borderOnly {
  border: 1px solid var(--borderColor);
  background: none;
  color: var(--textColor);
  box-shadow: 0 0 0 1px var(--borderContrastColor);
}

@media (prefers-color-scheme: dark) {
  :root:not(.theme-light) .buttonTransparent:focus,
  :root:not(.theme-light) .buttonTransparent:hover {
    background: var(--borderColor);
  }
}

@media (prefers-color-scheme: dark) {
  :root:not(.theme-light) .buttonPrimary {
    color: #cddfe6;
  }
}
